import { isPracticeQuestion as _isPracticeQuestion, questionTypeDisplayString, questionTypeIcon } from '@/../../types';
import { anamneseCharlyConfigStore } from '@rose/common-ui/src/anamnese/state/anamneseCharlyConfigStore';
import { omit } from 'lodash';
import { defineComponent } from 'vue';
import AnamneseQuestionEditorDialog from './AnamneseQuestionEditorDialog.vue';
import { anamneseContextStore } from '@rose/common-ui/src/anamnese/state/anamneseContextStore';
import { anamneseSettingsEventBus } from '@/views/Settings/Anamnese/anamneseSettingsEventBus';
export default defineComponent({
  components: {
    AnamneseQuestionEditorDialog: AnamneseQuestionEditorDialog
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    charlyAnamneseConfig: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      showQuestionEditor: false,
      questionTypeDisplayString: questionTypeDisplayString,
      questionTypeIcon: questionTypeIcon
    };
  },
  computed: {
    questionFull: function questionFull() {
      if (!this.question.isCharlyQuestion) {
        return this.question;
      } else {
        var charlyQuestion = anamneseCharlyConfigStore.getCharlyQuestionById(this.question.questionID);
        return Object.assign(Object.assign({}, this.question), charlyQuestion);
      }
    },
    charlyQuestionNotFound: function charlyQuestionNotFound() {
      return !!this.question.isCharlyQuestion && !anamneseCharlyConfigStore.getCharlyQuestionById(this.question.questionID);
    },
    isPracticeQuestion: function isPracticeQuestion() {
      return _isPracticeQuestion(this.question);
    }
  },
  methods: {
    saveNewQuestion: function saveNewQuestion(question) {
      anamneseSettingsEventBus.$emit('saveNewQuestion', question);
    },
    // QUESTION CRUD
    editQuestion: function editQuestion() {
      this.showQuestionEditor = true; // this.editingQuestion = question;
    },
    cancelEditQuestion: function cancelEditQuestion() {
      this.showQuestionEditor = false; // this.editingQuestion = null;
    },
    saveEditedQuestion: function saveEditedQuestion(question) {
      this.showQuestionEditor = false; // omit charly specific properties

      this.$emit('edited', omit(question, ['alert', 'visibleForPatient', 'visibleForPatient', '_links', 'position']));
    },
    deleteEditedQuestion: function deleteEditedQuestion(question) {
      this.showQuestionEditor = false;
      this.$emit('deleted', question);
    },
    getTranslatedString: anamneseContextStore.getTranslatedString,
    setTranslatedString: anamneseContextStore.setTranslatedString
  }
});