import {
  CustomAnamneseQuestionTypes,
  IAnamneseCharlyQuestion,
  IQuestionnaire,
  IQuestionnaireQuestionElement,
  QuestionnaireElementType,
  stableHashQuestionnaire,
} from '../../../../types/';
import { roseNanoid } from '../../nanoid';

// export const anamneseQuesitonnaireStore = reactive({
//   state: {
//     elements: [] as IQuestionnaireElement[],
//     questionnaires: [] as IQuestionnaire[],
//   },
//   getElement(elementId: string) {
//     return this.state.elements.find(e => e.id === elementId)!;
//   },
//   addElements(elements: IQuestionnaireElement[]) {
//     this.state.elements.push(...elements);
//   },
//   addElement(elements: IQuestionnaireElement) {
//     this.state.elements.push(elements);
//   },
//   getQuestionnairesThatContainElement(elementId: string) {
//     return this.state.questionnaires.filter(q =>
//       [...q.questionnairePages, q.signaturePage].some(page => page.pageElementIds.includes(elementId)),
//     );
//   },
// });

export function createCharlyQuestionElements(
  charlyQuestions: IAnamneseCharlyQuestion[],
): IQuestionnaireQuestionElement[] {
  // convert charly question to questionnaire question

  function convertCharlyQustionToQuestionnaireQuestionElement(
    charlyQuestion: IAnamneseCharlyQuestion,
  ): IQuestionnaireQuestionElement {
    return {
      type: QuestionnaireElementType.QUESTION,
      id: 'charlyQuestion_' + String(charlyQuestion.questionID),
      question: {
        question: charlyQuestion.question,
        questionID: charlyQuestion.questionID,
        questionLong: charlyQuestion.questionLong,
        isCharlyQuestion: true,
        optional: true,
        fullWidth: false,
        valueType: CustomAnamneseQuestionTypes.BOOLEAN,
        valueTypeConfig: {
          commentFieldOption: 'ifYes',
        },
      },
    };
  }

  return charlyQuestions
    .sort((a, b) => a.position - b.position)
    .map(convertCharlyQustionToQuestionnaireQuestionElement);
}

export function addAllCharlyQuestions(
  questionnaire: IQuestionnaire,
  charlyQuestionElements: IQuestionnaireQuestionElement[],
) {
  const defaultQuestionsPerPage = 8;

  let usedQuestionElms = questionnaire.questionnairePages.flatMap(page => page.pageElements);
  let remainingCharlyQuestionElements = charlyQuestionElements.filter(
    charlyQuestionElement => !usedQuestionElms.find(usedQuestionElm => usedQuestionElm.id === charlyQuestionElement.id),
  );

  for (let i = 0; i < remainingCharlyQuestionElements.length; i += defaultQuestionsPerPage) {
    questionnaire.questionnairePages.push({
      title: '',
      hidden: false,
      pageElements: remainingCharlyQuestionElements.slice(i, i + defaultQuestionsPerPage),
      pagebreak: false,
      id: roseNanoid(),
    });
  }
}

export function generateInitialQuestionnaire({
  displayTitle,
  title,
  overrideId,
}: {
  displayTitle: string;
  title: string;
  overrideId?: string;
}): IQuestionnaire {
  let newQuestionnaire: IQuestionnaire = {
    updatedAt: new Date().getTime(),
    updatedBy: '',
    id: overrideId || roseNanoid(6),
    deactivated: false,
    prefillPatientDetails: true,
    prefillQuestions: false,
    recallMonths: 0,
    displayTitle: displayTitle,
    allowExternalAccess: false,
    skipReviewProcess: false,
    title: title,
    questionnairePages: [
      {
        id: roseNanoid(6),
        pagebreak: false,
        hidden: false,
        title: 'Persönliche Daten',
        pageElements: [
          {
            type: QuestionnaireElementType.RICH_TEXT,
            id: roseNanoid(6),
            richText: '<h3>Willkommen in unserer Praxis</h3><p>Bitte füllen Sie den Fragebogen aus.</p>',
          },
          {
            type: QuestionnaireElementType.PATIENT_INFO_FORM,
            id: 'PATIENT_INFO_FORM',
          },
        ],
      },
    ],
    signaturePage: {
      id: roseNanoid(6),
      pagebreak: false,
      hidden: false,
      title: 'Unterschrift',
      pageElements: [
        {
          richText: '<p>Bitte überprüfen Sie Ihre Angaben und unterschreiben Sie das Dokument unten.</p>',
          type: QuestionnaireElementType.RICH_TEXT,
          id: roseNanoid(),
        },
        { type: QuestionnaireElementType.DOCUMENT_PREVIEW, id: roseNanoid() },
        {
          richText: '<p>Mit Ihrer Unterschrift stimmen Sie der elektronischen Übermittlung zu.</p>',
          type: QuestionnaireElementType.RICH_TEXT,
          id: roseNanoid(),
        },
        { type: QuestionnaireElementType.DOCUMENT_SIGNATURE, id: roseNanoid() },
      ],
    },
    initialGeneratedHash: '',
  };

  markQuestionnaireAsInitialGenerated(newQuestionnaire);

  return newQuestionnaire;
}

export function markQuestionnaireAsInitialGenerated(questionnaire: IQuestionnaire) {
  // mark all elements as initialGenerated
  [...questionnaire.questionnairePages, questionnaire.signaturePage].forEach((page, pageNum) => {
    page.pageElements.forEach(pageElm => {
      pageElm.initialGenerated = true;
    });
  });

  questionnaire.initialGeneratedHash = stableHashQuestionnaire(questionnaire);
}
